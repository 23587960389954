import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useLayoutEffect } from "react"
import logo from '../images/glowna/logo.svg'
import fi32 from "../static/img/favicon-32x32.png"
import fi57 from "../static/img/apple-icon-57x57.png"
import fi76 from "../static/img/apple-icon-76x76.png"
import fi96 from "../static/img/android-icon-96x96.png"
import fi120 from "../static/img/apple-icon-120x120.png"
import fi192 from "../static/img/android-icon-192x192.png"
import fi152 from "../static/img/apple-icon-152x152.png"
import fi180 from "../static/img/apple-icon-180x180.png"
import fi144 from "../static/img/apple-icon-144x144.png"
import { useTranslation } from "react-i18next"
import hrefObj from "../static/js/langObj"
import Helmet from 'react-helmet'
// import i18next from "../i18next"

const Header = (props) => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language || "pl"
  const prefix = currentLang === "pl" ? '' : `/${currentLang}`;
  const currentPath = props.path;
  const toggleMobileMenu = () => {
    if (window.innerWidth <= 1024) {
      document.querySelector("body").classList.toggle("toggled-on")
    }
  }
  useLayoutEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollY = window.pageYOffset
      const header = document.getElementById("header")
      if (scrollY > 0) {
        header.classList.add("scrolled")
        document.body.classList.add('scrolled');
      } else {
        header.classList.remove("scrolled");
        document.body.classList.remove("scrolled")
      }
    })
  }, []);
  return (
    <header className="header max-w-screen-xxl mx-auto" id="header">
      <Helmet>
        <title>{hrefObj[currentLang].title}</title>
        <meta name="description" content={hrefObj[currentLang].desc} />
        <link rel="icon" href={fi32} sizes="32x32" />
        <link rel="icon" href={fi57} sizes="57x57" />
        <link rel="icon" href={fi76} sizes="76x76" />
        <link rel="icon" href={fi96} sizes="96x96" />
        <link rel="icon" href={fi120} sizes="120x120" />
        <link rel="icon" href={fi192} sizes="192x192" />

        <link rel="shortcut icon" sizes="192x192" href={fi192} />

        <link rel="apple-touch-icon" href={fi120} sizes="120x120" />
        <link rel="apple-touch-icon" href={fi152} sizes="152x152" />
        <link rel="apple-touch-icon" href={fi180} sizes="180x180" />

        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content={fi144} />

        <meta name="msapplication-config" content="/browserconfig.xml" />
      </Helmet>
      <nav className="mx-auto items-stretch flex flex-wrap items-center justify-between tb:pr-6 uppercase">
        <Link className="logo bg-brand flex z-10" to={`${prefix}/`}>
          <img src={logo} />
        </Link>
        <span
          id="menu-toggle"
          onClick={toggleMobileMenu}
          className="menu-toggle"
        >
          <span className="lines"></span>
        </span>
        <nav className="main-nav text-center flex items-stretch content-center flex-wrap">
          <Link
            to={`${prefix}/${hrefObj[currentLang].about}/`}
            onClick={toggleMobileMenu}
            data-active={
              currentPath === `${prefix}/${hrefObj[currentLang].about}/`
            }
            className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap"
          >
            {t("general.about")}
          </Link>
          <Link
            to={`${prefix}/${hrefObj[currentLang].fleet}/`}
            onClick={toggleMobileMenu}
            data-active={
              currentPath === `${prefix}/${hrefObj[currentLang].fleet}/`
            }
            className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap"
          >
            {t("general.fleet")}
          </Link>
          <Link
            to={`${prefix}/${hrefObj[currentLang].career}/`}
            onClick={toggleMobileMenu}
            data-active={
              currentPath === `${prefix}/${hrefObj[currentLang].career}/`
            }
            className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap"
          >
            {t("general.career")}
          </Link>
          <Link
            to={`${prefix}/${hrefObj[currentLang].contact}/`}
            onClick={toggleMobileMenu}
            data-active={
              currentPath === `${prefix}/${hrefObj[currentLang].contact}/`
            }
            className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap"
          >
            {t("general.contact")}
          </Link>
          <a href="/ISO-14001-2015.pdf" className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap">
            ISO 14001-2015
          </a>
          <a href={t("general.isoHref")} className="main-nav__pagelink px-8 tb:py-0 py-4 tb:flex-1 whitespace-no-wrap">
            {t("general.iso")}
          </a>
          <span className="language-nav mt-5 tb:mt-0 tb:ml-4 flex content-center tb:flex-wrap">
            <a data-active={currentLang === "pl"} href="/">
              PL
            </a>
            <a data-active={currentLang === "en"} href="/en/">
              EN
            </a>
            <a data-active={currentLang === "de"} href="/de/">
              DE
            </a>
          </span>
        </nav>
      </nav>
    </header>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
